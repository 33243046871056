$(function () {

    //
    // A simple way to give a swal based confirmation popup using:
    //
    // data-confirm, data-message, data-cancel, data-action...
    //
    // You can also add data-warning to form inputs when the confirm message is on a form button
    // such that if those values are empty they will be listed as warnings.
    //

    let confirmPopup = function (e) {
        var clickedButton = $(this);
        var warnings      = [];

        if (typeof this.form !== 'undefined') {
            if (!this.form.checkValidity()) {
                return;
            }

            for (var pair of new FormData(this.form).entries()) {
                var name  = pair[0];
                var value = pair[1];

                if (value == '') {
                    var element = document.querySelector('[name="' + name + '"]');

                    if (element && element.dataset.warning) {
                        warnings.push(element.dataset.warning);
                    }

                } else {
                    var element = document.querySelector('[name="' + name + '"] option:checked');

                    if (element && element.dataset.warning) {
                        warnings.push(element.dataset.warning);
                    }
                }
            }
        }

        if (warnings.length) {
            warnings = '<ul>' + warnings.map(function(warning) {
                return '<li>' + warning + '</li>';
            }) + '</ul>';
        } else {
            warnings = '';
        }

        Swal.fire({
                title: !clickedButton.data("title")
                    ? "Are you sure?"
                    : clickedButton.data("title"),
                html: !clickedButton.data("message")
                    ? "<p>Are you sure you wish to delete this record and all associated data?</p>"
                    : `<p>${clickedButton.data("message")}</p>` + warnings,
                showCancelButton: true,
                confirmButtonColor: "#8A24CE",
                cancelButtonText: !clickedButton.data("cancel")
                    ? "Cancel"
                    : clickedButton.data("cancel"),
                confirmButtonText: !clickedButton.data("action")
                    ? "Yes, delete it!"
                    : clickedButton.data("action"),
                customClass: {
                    container: !clickedButton.data("class")
                        ? '-default'
                        : clickedButton.data("class")
                }
            }
        ).then(function(result) {
            if (result.isConfirmed) {
                if (clickedButton.is("a")) {
                    window.location.href = clickedButton.attr("href");
                } else {
                    clickedButton.unbind("click", confirmPopup);
                    clickedButton.click();
                }
            }
        });

        e.preventDefault();
        return false;
    };


    $('[data-confirm]').click(confirmPopup);

});
