import axios from 'axios';

import './core/main';
import './modules/enable-external-links';

import './modules/tooltips';

import './modules/data-change';
import './modules/data-confirm';
import './modules/data-show-password';
import './modules/data-text-toggle';

import './modules/enable-date-pickers';
import './modules/enable-external-links';
import './modules/enable-safe-forms';
import './modules/enable-remodal';

import './modules/auto-resize-iframes';
