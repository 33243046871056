$(function() {

    //
    // Disable all duplicate form submits
    //

    $("form").not('[data-unsafe]').submit(function () {
        if (this.checkValidity()) {
            $(this).submit(function () {
                return false;
            });
            return true;
        }

        else {
            return false;
        }
    });

});
