$(function () {

    //
    // Simple mechanism to make an element show/hide password
    //
    // <button data-show-password="#password-field">Show</button>
    //

    $('[data-show-password]').on('click', function (event) {
        var target = $(event.target);
        var field  = $(target.data('showPassword'));
        var type   = field.attr('type');

        if (type == 'password') {
            field.attr('type', 'text');
        } else {
            field.attr('type', 'password');
        }
    });

});
