$('.js-datePicker').each(function(i, value) {
    var $this = $(this);
    var $uiField = $this.find('.js-dateUi');
    var $startDate = $this.find('.js-startDate');
    var $endDate = $this.find('.js-endDate');

    flatpickr($uiField[0], {
        mode: 'range',
        dateFormat: 'm/d/Y',
        defaultDate: [
            $startDate.val() || null,
            $endDate.val() || null,
        ],
        onClose: function(selectedDates) {
            var datesConverted = [];

            $.each(selectedDates, function(i, value) {
                datesConverted[i] = moment(value).format('M/DD/YYYY')
            });

            if (datesConverted.length > 1) {
                $startDate.val(datesConverted[0]);
                $endDate.val(datesConverted[1]);
            } else if (datesConverted.length === 1) {
                $uiField.val(datesConverted[0]);
                $startDate.val(datesConverted[0]);
                $endDate.val(datesConverted[0]);
            } else {
                $startDate.val(null);
                $endDate.val(null);
            }
        },
    });
});
