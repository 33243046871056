$(function () {

    //
    // Resize all iframes so that their contents fit in the page without scrolling
    //

    $('iframe').each(function () {
        let iframe = this;

        if (iframe.src && window.location.host == new URL(iframe.src).host) {
            let resize = function () {
                iframe.style.padding = 0;
                iframe.style.margin  = 0;
                iframe.style.width   = '100%';
                iframe.style.height  = iframe.contentWindow.document.body.scrollHeight + 'px';
            };

            window.addEventListener('resize', function() {
                resize();
            });

            iframe.addEventListener('load', function() {
                resize();
            });
        }
    });


});
