$(function () {

    //
    // Utility to toggle the text of an element on click
    //

    $('[data-text-toggle]').on('click', function (event) {
        var target = $(event.target);
        var text   = target.text();

        target.text(target.data('textToggle'));
        target.data('textToggle', text);
    });

});
