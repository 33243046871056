$(function () {

    //
    // A simple way to post a single key/value pair to an endpoint to update a field.
    //

    $('[data-change]').change(function (e) {
        let data = {};
        let that = this;

        if (this.type == 'checkbox') {
            data[this.name] = this.checked ? this.value : '0';
        } else {
            data[this.name] = this.value;
        }

        this.setAttribute('disabled', 'true');

        $.post(
            this.dataset.change,
            {
                ...data,
                'csrf::token': this.dataset.csrf
            },
            function (data) {
                that.removeAttribute('disabled');
            }
        );
    });

});



