$(function () {

    //
    // Add data-remodal-open to an element to have it open in remodal on load, use its
    // data-remodal-id attribute to identify it so when cancelled it stays dismissed for the
    // sesssion.
    //

    $('[data-remodal-open]').each(function() {
        let key = 'remodal-' + this.dataset.remodalId;

        if (!sessionStorage.getItem(key)) {
            $(this).on('cancellation', this, function() {
                sessionStorage.setItem(key, 1);
            });

            $(this).remodal().open();
        }
    });

});

