$(function () {

    //
    // Simple tooltips using the title attribute
    //
    // <a href="#" class="tooltip" title="This is the tooltip you'll see">Link</a>
    //

    $('.tooltip').tipsy({
        gravity: "n",
        fade: true,
        html: true
    });

});

