$(function () {

    //
    // Any link with rel="external" will explicitly be opened in a new tab.
    //

    $('[rel="external"]').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        window.open(this.href, "_blank");
    });


    //
    // Any offsite link with a host, not explicitly external, will implicitly be opened in a new tab.
    //

    $('a:not([rel="external"])').each(function () {
        var has_host   = new RegExp('#://#');
        var match_host = new RegExp("#://" + window.location.host + "#");

        if (has_host.test(this.href) && !match_host.test(this.href)) {
            console.log('here');
            $(this).click(function (event) {
                event.preventDefault();
                event.stopPropagation();
                window.open(this.href, "_blank");
            });
        }
    });

});
